import _typeof from "/Users/2024/cms/cms_admin_template/node_modules/@babel/runtime/helpers/esm/typeof";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.regexp.constructor.js";
// 日期格式化
export function parseTime(time, pattern) {
  if (arguments.length === 0 || !time) {
    return null;
  }

  var format = pattern || '{y}-{m}-{d} {h}:{i}:{s}';
  var date;

  if (_typeof(time) === 'object') {
    date = time;
  } else {
    if (typeof time === 'string' && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }

    if (typeof time === 'number' && time.toString().length === 10) {
      time = time * 1000;
    }

    date = new Date(time);
  }

  var formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  var time_str = format.replace(/{(y|m|d|h|i|s|a)+}/g, function (result, key) {
    var value = formatObj[key]; // Note: getDay() returns 0 on Sunday

    if (key === 'a') {
      return ['日', '一', '二', '三', '四', '五', '六'][value];
    }

    if (result.length > 0 && value < 10) {
      value = '0' + value;
    }

    return value || 0;
  });
  return time_str;
} // 表单重置

export function resetForm(refName) {
  if (this.$refs[refName]) {
    this.$refs[refName].resetFields();
  }
} // 添加日期范围

export function addDateRange(params, dateRange) {
  var search = params;
  search.beginTime = '';
  search.endTime = '';

  if (dateRange != null && dateRange !== '') {
    search.beginTime = this.dateRange[0];
    search.endTime = this.dateRange[1];
  }

  return search;
} // 回显数据字典

export function selectDictLabel(datas, value) {
  var actions = [];
  Object.keys(datas).map(function (key) {
    if (datas[key].dictValue === '' + value) {
      actions.push(datas[key].dictLabel);
      return false;
    }
  });
  return actions.join('');
}
export function selectItemsLabel(datas, value) {
  var actions = [];
  Object.keys(datas).map(function (key) {
    if (datas[key].key === '' + value) {
      actions.push(datas[key].value);
      return false;
    }
  });
  return actions.join('');
} // 字符串格式化(%s )

export function sprintf(str) {
  var args = arguments;
  var flag = true;
  var i = 1;
  str = str.replace(/%s/g, function () {
    var arg = args[i++];

    if (typeof arg === 'undefined') {
      flag = false;
      return '';
    }

    return arg;
  });
  return flag ? str : '';
} // 转换字符串，undefined,null等转化为""

export function praseStrEmpty(str) {
  if (!str || str === 'undefined' || str === 'null') {
    return '';
  }

  return str;
}
export function picsBeforeUpload(file) {
  var isRightSize = file.size / 1024 / 1024 < 2;

  if (!isRightSize) {
    this.$message.error('文件大小超过 2MB');
  }

  var isAccept = new RegExp('image/*').test(file.type);

  if (!isAccept) {
    this.$message.error('应该选择image/*类型的文件');
  }

  return isRightSize && isAccept;
}